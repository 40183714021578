import React from "react"
import Blocks from "components/blocks"
import Clamp from "components/clamp"
import Feature from "components/feature"
import Hero from "components/hero"
import Text from "components/text"

export default () => {
  return (
    <>
      <Hero>
        We focus on corporate strategy, operational excellence and capital
        allocation
      </Hero>
      <Clamp inset>
        <h1>Our strategy and approach</h1>
        <Text type="lede">
          We provide customized capital solutions to help finance the specific
          needs of our partner companies
        </Text>
      </Clamp>
      <Feature>
        <div>
          <h2>Role</h2>
          <p>
            We seek to lead and be an engaged, value add capital partner with a
            focus on corporate strategy, operational excellence and capital
            allocation to deliver financial performance throughout the business
            cycle.
          </p>
        </div>
        <div>
          <h2>Flexible Capital Solutions</h2>
          <p>
            We provide customized capital solutions to help finance the specific
            needs of our investee companies, making investments across their
            capital structure. Our investments typically include common and
            preferred stock, convertible stock and other equity-like investment
            structures.
          </p>
        </div>
      </Feature>
      <Clamp inset>
        <h2>Funding uses</h2>
        <Text>
          GPI Capital provides capital to its partner companies for a wide array
          of uses.
        </Text>
        <h3>Transformational capital</h3>
        <Blocks content="capital">
          <div>
            <h4>Organic initiatives</h4>
            <p>
              Funding corporate projects, geographic or product/service
              expansion, penetrating new or existing markets, platform
              extensions and growing teams
            </p>
          </div>
          <div>
            <h4>Mergers &amp; acquisitions</h4>
            <p>
              Funding acquisitions, bolt-on new capabilities or verticalize
              technology stacks and supply chains
            </p>
          </div>
          <div>
            <h4>Liquidity solutions</h4>
            <p>
              Funding governance changes, ownership transitions and
              recapitalizations
            </p>
          </div>
        </Blocks>
        <h2>Investment criteria</h2>
        <Text>
          We invest in late stage growth and mature companies, primarily in
          North America, that have achieved scale, typically with revenues over
          $100 million and equity values between $500 million and $5 billion
          across technology, consumer and industrial sectors.
        </Text>
        <h3>Industry focus</h3>
        <Blocks content="industry">
          <div>
            <h4>Technology</h4>
            <ul>
              <li>Internet</li>
              <li>Social/marketplaces</li>
              <li>E-communications</li>
              <li>Software and services</li>
            </ul>
          </div>
          <div>
            <h4>Consumer</h4>
            <ul>
              <li>Retail/e-commerce</li>
              <li>Consumer finance</li>
              <li>Travel/leisure</li>
              <li>Services</li>
            </ul>
          </div>
          <div>
            <h4>Industrial</h4>
            <ul>
              <li>Multi-industry/services</li>
              <li>Equipment/machinery</li>
              <li>Transport/logistics</li>
            </ul>
          </div>
        </Blocks>
        <h2>Value creation approach</h2>
        <Text>
          We work closely in partnership with our portfolio companies’
          stakeholders and management to drive significant financial and
          operational improvements. We have a wealth of knowledge, best
          practices and a large supportive network to share with our portfolio
          companies. Each engagement is highly customized to the respective
          needs of the companies.
        </Text>
        <Blocks content="value">
          <div>
            <h4>Strategy</h4>
            <h5>Commercial</h5>
            <p>
              Go-to market strategies and market assessment to drive rapid
              topline growth
            </p>
            <h5>Corporate development</h5>{" "}
            <p>M&amp;A, capital allocation and liquidity analyses</p>
          </div>
          <div>
            <h4>Operational</h4>
            <h5>Technology</h5>
            <p>Best practices and infrastructure</p>
            <h5>Talent</h5>
            <p>Organization, people and vendor search</p>
            <h5>Cost savings</h5>
            <p>Best practices to maximize profitable growth</p>
          </div>
          <div>
            <h4>Business &amp; market</h4>
            <h5>Industry landscape</h5>
            <p>
              Competitive analysis and industry insights and product disruption
              risks
            </p>
            <h5>Corporate partnerships</h5>
            <p>
              Leverage extensive and deep network to drive incremental growth
              and operational efficiencies
            </p>
          </div>
        </Blocks>
        <h2>Long term, sustainable growth - ESG</h2>
        <Text>
          GPI Capital integrates Environmental, Social and Governance (ESG)
          considerations into its diligence and analysis. We believe that an ESG
          analysis is key in the exercise of our fiduciary duty to protect our
          investors’ long-term interest by providing additional tools and lenses
          through which we can identify risks and opportunities of our
          investments.
        </Text>
      </Clamp>
    </>
  )
}
