import React from "react"
import PropTypes from "prop-types"
import { join } from "hooks/use-array"
import * as styles from "./text.module.less"

export default function Text({ type, children }) {
  return <p className={join(styles.root, styles[type])}>{children}</p>
}

Text.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
}
