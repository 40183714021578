import React from "react"
import PropTypes from "prop-types"
import Clamp from "components/clamp"
import * as styles from "./feature.module.less"

export default function Feature({ children }) {
  return (
    <div className={styles.root}>
      <Clamp className={styles.deck}>{children}</Clamp>
    </div>
  )
}

Feature.propTypes = {
  children: PropTypes.node.isRequired,
}
