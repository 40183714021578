import React from "react"
import PropTypes from "prop-types"
import { join } from "hooks/use-array"
import * as styles from "./blocks.module.less"

export default function Blocks({ content, children }) {
  return <div className={join(styles.root, styles[content])}>{children}</div>
}

Blocks.propTypes = {
  content: PropTypes.oneOf([`capital`, `industry`, `value`]),
  children: PropTypes.node.isRequired,
}
